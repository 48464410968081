import { Layout } from "../../styled/Layout";
import AppFooter from "../../../common/AppFooter";
import {
  BodyTextRocket,
  Heading1Rocket,
  LinkRocket,
  ThemedButtonRocket,
} from "ccp-common-ui-components";
import {
  CancelLink,
  ReceiptPreferencesButtonsRocket,
  HeaderSectionPreferencesRocket,
  HeadingContainerPreferencesRocket,
  ReceiptPreferencesContainerRocket,
  ContainerRocket,
  LineLabelRocketStyled,
} from "./ReceiptPreferencesPageStyles";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";
import styled from "styled-components";
import { IconReceiptOutline, IconWrapper } from "@coles/rocket";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import configuration from "../../../config/Configuration";

const StyledOL = styled.ol`
  margin-block-start: 0.5em;
  padding-inline-start: 1.25em;
`;

const RowAlignedDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: centre;
`;

const LinkRocketStyled = styled(LinkRocket)`
  padding: 0;
`;

const ReceiptPreferencesPageRocket = () => {
  const { isLoadingPreferences } = useSelector(
    (state: RootState) => state.customerProfilePreferences
  );

  return (
    <Layout>
      <ContainerRocket>
        <HeaderSectionPreferencesRocket
          isLoading={isLoadingPreferences}
          data-testid="welcome-message"
        >
          <RowAlignedDiv>
            <IconWrapper
              icon={<IconReceiptOutline height="30" width="30" />}
              indicator={""}
            />
            <HeadingContainerPreferencesRocket>
              <Heading1Rocket data-testid="preferences-heading">
                Receipt preferences
              </Heading1Rocket>
            </HeadingContainerPreferencesRocket>
          </RowAlignedDiv>
        </HeaderSectionPreferencesRocket>

        <ReceiptPreferencesContainerRocket>
          <form data-testid="manage-my-receipt-preferences-form" noValidate>
            <>
              <LineLabelRocketStyled className="sentry-unmask">
                Go paperless with Coles barcode
              </LineLabelRocketStyled>
              <BodyTextRocket>
                <StyledOL className="sentry-unmask">
                  <li>Turn on Digital receipts only</li>
                  <li>
                    Scan your Coles barcode each time you checkout instore
                  </li>
                  <li>
                    To access digital receipts, visit{" "}
                    <LinkRocketStyled
                      tabIndex={0}
                      id="order-and-purchase"
                      data-testid="order-and-purchase"
                      label="Orders & purchases"
                      variant="inline"
                      size="small"
                      href={configuration.cuspActiveOrdersUrl}
                      target="_blank"
                    />
                  </li>
                </StyledOL>
              </BodyTextRocket>
              <ReceiptPreferencesButtonsRocket>
                <ThemedButtonRocket
                  data-testid="save-button-rocket"
                  className="sentry-unmask"
                >
                  Save
                </ThemedButtonRocket>
                <CancelLink
                  tabIndex={0}
                  href={getReturnToLink()}
                  className="sentry-unmask"
                >
                  Cancel
                </CancelLink>
              </ReceiptPreferencesButtonsRocket>
            </>
          </form>
        </ReceiptPreferencesContainerRocket>
      </ContainerRocket>
      <AppFooter />
    </Layout>
  );
};

export default ReceiptPreferencesPageRocket;
