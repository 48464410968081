import styled from "styled-components";
import { BodyTextRocket, Link } from "ccp-common-ui-components";
import { Settings } from "../profile/ProfileSettings";
import { rdsMargin, rdsPadding, Spacing } from "@coles/rocket";
import { screenSize } from "../../common/styles/ScreenSizes";

export const ChangePasswordContainer = styled(Settings)`
  margin-bottom: 50px;
  padding: 18px 25px;
  position: relative;
`;

export const RequiredFieldsText = styled.p`
  font-size: 16px;
  font-family: "Source Sans", "sans-serif";
  line-height: 19px;
  text-align: left;
`;

export const RequiredFieldsTextRocket = styled(BodyTextRocket).attrs<{
  className: string;
  children: React.ReactNode;
}>({
  variant: "body_300",
})`
  ${rdsMargin.s6.bottom};
`;

interface ThemedInputLabelProps {
  hasError?: boolean;
}

export const ThemedInputLabel = styled.label<ThemedInputLabelProps>`
  display: block;
  color: ${(props) => (props.hasError ? "#e01a22" : "black")};
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
`;

export const ThemedInputLabelRocket = styled(BodyTextRocket).attrs({
  variant: "body_300",
})`
  ${rdsMargin.s2.top};
`;

export const ThemedErrorText = styled.div`
  color: #e01a22;
  font-size: 14px;
  padding: 10px 0 0 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const ChangePasswordContainerRocket = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  ${rdsMargin.s3.bottom};
  ${rdsPadding.s4.vertical};
  ${rdsPadding.s6.horizontal};

  @media ${screenSize.mobile} {
    margin: 0 -${Spacing.s6}px ${Spacing.s3}px -${Spacing.s6}px;
  }
`;

export const ChangePasswordPageContainerRocket = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: start;
  background-color: #ffffff;
  ${rdsMargin.none.all};
  ${rdsPadding.s10.right};

  @media ${screenSize.mobile} {
    margin: 0 -${Spacing.s6}px ${Spacing.s3}px -${Spacing.s6}px;
  }
`;

export const StyledCenteredDiv = styled.div`
  text-align: center;
  ${rdsMargin.s5.top};
`;

export const InputDivStyled = styled.div`
  ${rdsMargin.s2.bottom};
`;

export const ThemedInputDiv = styled.div`
  ${rdsMargin.s6.bottom};
  ${rdsMargin.none.top};
`;
