import { useEffect } from "react";
import "mutationobserver-shim";
import { Switch, useRouteMatch, Redirect, useLocation } from "react-router-dom";
import { ChangePasswordSuccessPage } from "./ChangePasswordSuccessPage";
import { ChangePasswordFailedPage } from "./ChangePasswordFailedPage";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { clearPasswordDetails } from "../../store/update-password/UpdatePasswordSlice";
import { RootState } from "../../store/Store";
import { SubPageHeader, SubPageHeaderRocket } from "../styled/SubPageHeader";
import ProtectedRoute from "../protectedRoute/ProtectedRoute";
import { getReturnToLink } from "../../common/utils/RedirectionHelpers";
import { redirect } from "../../common/utils/NavigationHelpers";
import { useReturnUrl } from "../../common/hooks/useReturnUrl";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import { SecureLoginIconRocket } from "ccp-common-ui-components";
import { ChangePasswordPageContainerRocket } from "./ChangePasswordFormStyles";

export interface BackButtonProps {
  backButtonUrl: string | undefined;
}

export function ChangePasswordPage({ backButtonUrl }: BackButtonProps) {
  const isRocketEnabled = useRocketEnabled();

  let { path } = useRouteMatch();
  const { pathname: currentLocationPath } = useLocation();

  const dispatch = useDispatch();

  const userUpdatesPassword = useSelector(
    (state: RootState) => state.customerProfile.isUpdatingPassword
  );

  useEffect(() => {
    const options: ScrollToOptions = {
      top: 0,
      left: 0,
    };
    window.scrollTo(options);
  }, []);

  useEffect(() => {
    dispatch(clearPasswordDetails());
  }, [dispatch]);

  const { handleNavigation } = useReturnUrl(backButtonUrl);

  useEffect(() => {
    const returnToUrl = getReturnToLink(backButtonUrl);
    if (
      path !== currentLocationPath &&
      !userUpdatesPassword &&
      returnToUrl !== "/"
    ) {
      redirect(returnToUrl);
    }
  }, [userUpdatesPassword, backButtonUrl, currentLocationPath, path]);

  return (
    <>
      {isRocketEnabled ? (
        <ChangePasswordPageContainerRocket>
          <SubPageHeaderRocket className="sentry-unmask">
            <SecureLoginIconRocket size={20} /> Change password
          </SubPageHeaderRocket>
        </ChangePasswordPageContainerRocket>
      ) : (
        <SubPageHeader className="sentry-unmask">
          Change my password
        </SubPageHeader>
      )}
      <Switch>
        <ProtectedRoute exact path={path}>
          <ChangePasswordForm onCancel={handleNavigation} />
        </ProtectedRoute>
        <ProtectedRoute exact path={`${path}/success`}>
          {userUpdatesPassword ? (
            <ChangePasswordSuccessPage backButtonUrl={backButtonUrl} />
          ) : (
            <Redirect to="/" />
          )}
        </ProtectedRoute>
        <ProtectedRoute exact path={`${path}/failed`}>
          {userUpdatesPassword ? (
            <ChangePasswordFailedPage />
          ) : (
            <Redirect to="/" />
          )}
        </ProtectedRoute>
      </Switch>
    </>
  );
}
