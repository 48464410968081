import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AppFooter from "../../common/AppFooter";
import { SubPageHeader } from "../styled/SubPageHeader";
import { TeamMemberNumber } from "./TeamMemberNumber";
import { invalidTeamMemberNumberErrorMessage } from "../../common/constants/ErrorMessages";

import { Link, LinkRocket, ThemedButtonRocket } from "ccp-common-ui-components";
import { RootState } from "../../store/Store";
import { removeAllSpaces } from "../profile/Utils/PhoneUtils";
import { Container } from "../styled/Container";
import { InputDiv } from "../styled/InputDiv";
import { LineLabel } from "../styled/LineLabel";
import { Layout } from "../styled/Layout";
import { PageLoader } from "../common/PageLoader";
import { linkTeamMemberNumberAction } from "../../store/team-member-number/TeamMemberNumberSlice";
import {
  LinkButton,
  LinkButtonContainer,
  StyledHideableDiv,
} from "./TeamMemberLinkPageStyles";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import { rdsMargin, rdsPadding } from "@coles/rocket";
import { Settings } from "../profile/ProfileSettings";

type FormData = {
  teamMemberNumber: string;
};

export const LinkButtonContainerRocket = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const ContainerRocket = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  width: 67.5vw;
  max-width: 55em;

  ${rdsMargin.s8.bottom};
  ${rdsMargin.none.horizontal};
  ${rdsMargin.none.top};

  ${rdsPadding.s5.vertical};
  ${rdsPadding.s6.horizontal};

  @media ${screenSize.mobile} {
    width: 100%;
    ${rdsMargin.none.top};
    ${rdsMargin.s3.bottom};
    ${rdsMargin.none.horizontal};
  }

  @media ${screenSize.tablet} {
    width: 80.5vw;
    min-width: 620px;
  }
`;

export const StyledHideableDivRocket = styled(StyledHideableDiv)`
  ${rdsPadding.s6.bottom};
`;

export const LinkButtonRocketCancel = styled(LinkRocket)`
  ${rdsMargin.s3.top};
  ${rdsMargin.s7.left};
`;

export function TeamMemberLinkPage() {
  const isRocketEnabled = useRocketEnabled();

  const teamMemberNumberInputName = "teamMemberNumber";

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<FormData>();

  const memberNumber = useSelector(
    (state: RootState) => state.teamMemberNumber
  );

  const isLoading = memberNumber.isSendteamMemberNumberLinkRequest;

  const onSubmit = (data: FormData) => {
    const sanitizedValue = removeAllSpaces(data.teamMemberNumber);
    const isValid = /^[0-9]{13}$/.test(sanitizedValue);
    if (!isValid) {
      return invalidTeamMemberNumberErrorMessage;
    }

    dispatch(
      linkTeamMemberNumberAction({
        memberId: data.teamMemberNumber,
      })
    );
  };

  const isValidTeamMemberNumber = (value: string): boolean | string => {
    const sanitizedValue = removeAllSpaces(value);
    const isValid = /^[0-9]{13}$/.test(sanitizedValue);
    if (!isValid) {
      return invalidTeamMemberNumberErrorMessage;
    }
    return isValid;
  };

  const isMemberNumberEmpty = (value: string) => {
    return !value;
  };

  const setTeamMemberNumberValue = (value: string) => {
    const sanitizedTeamMemberNumber = removeAllSpaces(value);
    const isNotEmpty = !isMemberNumberEmpty(sanitizedTeamMemberNumber);

    const shouldValidate = isSubmitted && isNotEmpty;
    setValue(teamMemberNumberInputName, sanitizedTeamMemberNumber, {
      shouldDirty: true,
      shouldValidate,
    });
    if (!shouldValidate) {
      clearErrors();
    }
  };

  const LayoutChildren = isRocketEnabled ? (
    <>
      <ContainerRocket>
        <PageLoader isShown={isLoading} />
        <StyledHideableDivRocket isHidden={isLoading}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="add-edit-team-member-number-form"
            noValidate
          >
            <InputDiv>
              <TeamMemberNumber
                autoFocus
                id="team-member-number"
                hasValidationError={!!errors.teamMemberNumber?.message}
                successfullyLinked={
                  memberNumber && memberNumber.isTeamMemberNumberLinkSuccess
                }
                {...register(teamMemberNumberInputName, {
                  validate: {
                    validTeamMemberNumber: (value) =>
                      isValidTeamMemberNumber(value),
                  },
                  onChange: (e: any) => {
                    setTeamMemberNumberValue(e.target.value);
                  },
                })}
              />
            </InputDiv>

            <LinkButtonContainerRocket>
              <ThemedButtonRocket
                data-testid="save-button-rocket"
                label="Save"
                className="sentry-unmask"
              />
              <LinkButtonRocketCancel
                href="/"
                className="sentry-unmask"
                label="Cancel"
                variant="inline"
              />
            </LinkButtonContainerRocket>
          </form>
        </StyledHideableDivRocket>
      </ContainerRocket>
      <AppFooter />
    </>
  ) : (
    <>
      <SubPageHeader className="sentry-unmask">Link mycoles card</SubPageHeader>
      <Container>
        <PageLoader isShown={isLoading} />
        <StyledHideableDiv isHidden={isLoading}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="add-edit-team-member-number-form"
            noValidate
          >
            <InputDiv>
              <LineLabel htmlFor="team-member-number">
                mycoles card number
              </LineLabel>
              <TeamMemberNumber
                autoFocus
                id="team-member-number"
                hasValidationError={!!errors.teamMemberNumber?.message}
                successfullyLinked={
                  memberNumber && memberNumber.isTeamMemberNumberLinkSuccess
                }
                {...register(teamMemberNumberInputName, {
                  validate: {
                    validTeamMemberNumber: (value) =>
                      isValidTeamMemberNumber(value),
                  },
                  onChange: (e: any) => {
                    setTeamMemberNumberValue(e.target.value);
                  },
                })}
              />
            </InputDiv>
            <LinkButtonContainer>
              <LinkButton
                data-testid="save-button"
                type="submit"
                className="sentry-unmask"
              >
                Link card
              </LinkButton>
              <Link href="/" className="sentry-unmask">
                Cancel
              </Link>
            </LinkButtonContainer>
          </form>
        </StyledHideableDiv>
      </Container>
      <AppFooter />
    </>
  );

  return <Layout>{LayoutChildren}</Layout>;
}
