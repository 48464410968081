import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";
import { rdsMargin, rdsPadding } from "@coles/rocket";

export const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const AddressContainer = styled.div`
  align-self: center;
  border-top: 2px #c7c7c7 solid;
  padding: 18px 0 32px 0;
  width: 67.5vw;
  max-width: 55em;
  margin-bottom: 50px;

  @media ${screenSize.mobile} {
    width: 100%;
  }

  @media ${screenSize.tablet} {
    width: 80.5vw;
    min-width: 620px;
  }
`;

export const AddressHeader = styled.h2`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-size: 1.17em;
  font-weight: 500;
  line-height: 27px;
  margin: 0 0 24px 0;

  @media ${screenSize.mobile} {
    line-height: 32px;
  }
`;

export const SavedAddressesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CenteredButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RowAlignedDivRocket = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  align-self: flex-start;
`;
export const HeadingContainerRocket = styled.div`
  ${rdsPadding.s2.left};
  ${rdsPadding.s5.bottom};
  align-self: flex-start;
  @media ${screenSize.mobile} {
    white-space: nowrap;
  }
`;

export const DescriptionRocket = styled.div`
  text-align: center;
  align-self: flex-start;
  ${rdsPadding.s5.bottom};

  @media ${screenSize.mobile} {
    white-space: nowrap;
  }
`;

export const AddressContainerRocket = styled.div`
  align-self: center;
  border-top: 1.5px ${headers.lightGrey} solid;
  width: 26vw;
  max-width: 55em;
  align-self: flex-start;
  ${rdsPadding.s5.top};
  ${rdsPadding.s7.bottom};
  ${rdsMargin.s8.bottom};

  @media ${screenSize.mobile} {
    width: 100%;
  }

  @media ${screenSize.tablet} {
    width: 80.5vw;
    min-width: 620px;
  }
`;
