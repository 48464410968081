import styled from "styled-components";
import { Settings } from "../../profile/ProfileSettings";
import { screenSize } from "../../../common/styles/ScreenSizes";
import { BodyTextRocket, Link } from "ccp-common-ui-components";
import { rdsMargin, rdsPadding } from "@coles/rocket";

export const ReceiptPreferencesContainer = styled(Settings)`
  margin: 25px 25px 50px 25px;
  padding: 18px 25px;
  position: relative;
`;

export const ReceiptPreferencesDescriptionBold = styled.p`
  font-size: 18px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 8px;
  font-weight: bold;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ReceiptPreferencesDescriptionBoldLvl2 = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 0px;
  font-weight: bold;

  @media ${screenSize.mobile} {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ReceiptPreferencesDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 18px;
  margin-top: 0px;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ReceiptPreferencesButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 12px;
  align-items: center;
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const Divider = styled.div`
  border-top: 1px solid black;
`;

export const ContainerRocket = styled.div`
  ${rdsMargin.s8.bottom};
  ${rdsMargin.s6.left};
  ${rdsMargin.s6.right};
  ${rdsPadding.s6.horizontal};
  width: 100%;
  position: relative;
  @media ${screenSize.mobile} {
    margin: 0px;
  }
`;

export const HeaderSectionPreferencesRocket = styled.div<{
  isLoading: Boolean;
}>`
  ${rdsMargin.s4.bottom};
  visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};
`;

export const HeadingContainerPreferencesRocket = styled.div`
  ${rdsPadding.s2.left};
  ${rdsPadding.s2.bottom};
`;

export const ReceiptPreferencesContainerRocket = styled.div`
  align-self: center;
  border-top: 2px #e0e0e0 solid;

  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const ReceiptPreferencesButtonsRocket = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${rdsMargin.s6.top};
`;

export const LineLabelRocketStyled = styled(BodyTextRocket).attrs({
  variant: "body_300_bold",
})`
  ${rdsMargin.s6.top};
`;
